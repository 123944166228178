<template>
  <div class="flex h-11 items-center justify-center">
    <div class="pb-5">
      <div class="flex items-center space-x-3">
        <!-- <div
          class="rounded-lg border border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-900"
        >
          <div class="px-4 py-2.5">
            <div class="flex items-center">
              <SvgIcon class="mr-3 h-5 w-5" name="notes" />
              <span class="text-sm font-medium text-gray-900 dark:text-gray-50"
                >Add a Note</span
              >
            </div>
          </div>
        </div>
        <div
          class="rounded-lg border border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-900"
        >
          <div class="px-4 py-2.5">
            <div class="flex items-center">
              <SvgIcon class="mr-3 h-5 w-5" name="module" />
              <span class="text-sm font-medium text-gray-900 dark:text-gray-50"
                >Add a Module</span
              >
            </div>
          </div>
        </div> -->
        <div
          class="flex content-between items-start gap-6 rounded-lg bg-white px-6 py-2 shadow-lg"
        >
          <Popper hover v-if="!currentIsActive">
            <div v-if="shouldShowIcon">
              <SvgIcon
                class="h-8 w-8 pointer-events-none fill-gray-500"
                name="play"
              />
            </div>

            <div v-else>
              <SvgIcon
                class="h-8 w-8 cursor-pointer fill-green-400"
                name="play"
                @click="onClickRun()"
                v-show="!showRunLoader"
                :class="{
                  // 'hover:fill-green-600 active:fill-green-600': !hasErrors,
                  // 'opacity-40': hasErrors
                }"
              />
              <div
                class="flex h-8 w-8 rounded-full bg-green-500"
                v-show="showRunLoader"
              >
                <Spinner class="m-auto text-white" size="small" />
              </div>
            </div>

            <template #content>
              <div class="w-max max-w-lg rounded bg-white p-2 text-sm">
                {{ workflowMessage }}
              </div>
            </template>
          </Popper>
          <Popper hover v-else>
            <div>
              <SvgIcon
                class="h-8 w-8 cursor-pointer fill-yellow-300 hover:fill-yellow-500 active:fill-yellow-500"
                name="pauseYellow"
                @click="onClickRun()"
                v-show="!showRunLoader"
              />
              <div
                class="flex h-8 w-8 rounded-full bg-yellow-400"
                v-show="showRunLoader"
              >
                <Spinner class="m-auto text-white" size="small" />
              </div>
            </div>
            <template #content>
              <div class="w-max max-w-lg rounded bg-white p-2 text-sm">
                {{
                  currentIsScheduled
                    ? 'Pause Workflow Schedule'
                    : 'Pause Workflow'
                }}
              </div>
            </template>
          </Popper>
          <!-- 
          <div>
            <SvgIcon
              class="h-8 w-8 cursor-pointer fill-red-400 hover:fill-red-600 active:fill-red-600"
              name="stopRed"
            />
          </div>
          -->
          <Popper hover>
            <div>
              <SvgIcon
                class="h-8 w-8 cursor-pointer fill-blue-400"
                name="clockBlue"
                @click.stop="currentIsActive || $emit('show-schedule-modal')"
                :class="{
                  'hover:fill-blue-600 active:fill-blue-600': !currentIsActive,
                  'opacity-40': currentIsActive
                }"
              />
            </div>
            <template #content>
              <div class="w-max max-w-lg rounded bg-white p-2 text-sm">
                {{
                  currentIsActive
                    ? 'Pause the schedule to edit it.'
                    : 'Setup workflow schedule'
                }}
              </div>
            </template>
          </Popper>
          <Popper hover>
            <div>
              <SvgIcon
                class="h-8 w-8 cursor-pointer rounded-full hover:text-purple-700 active:text-purple-700 text-purple-500"
                name="calenderIcon"
                @click.stop="$emit('show-history')"
              />
            </div>
            <template #content>
              <div class="w-max max-w-lg rounded bg-white p-2 text-sm">
                View run history
              </div>
            </template>
          </Popper>

          <Popper hover>
            <div
              class="bg-gray-500 rounded-full p-1"
              :class="[
                {
                  'bg-green-500':
                    props.desktopDataSelected && props.desktopDataSelected._id
                }
              ]"
            >
              <SvgIcon
                class="h-6 w-6 rounded-full text-white"
                :class="[
                  { 'cursor-pointer': !currentIsActive },
                  { 'cursor-not-allowed pointer-events-none': currentIsActive }
                ]"
                name="desktop"
                @click.stop="$emit('show-desktop-modal')"
              />
            </div>
            <template #content>
              <div class="w-max max-w-lg rounded bg-white p-2 text-sm">
                {{
                  props?.desktopDataSelected?.name
                    ? props?.desktopDataSelected?.name
                    : 'Add desktop to non social automations'
                }}
              </div>
            </template>
          </Popper>
        </div>

        <!-- <div class="flex items-center space-x-4">
          <div
            class="rounded-lg border border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-900"
          >
            <div class="p-2">
              <div class="flex items-center">
                <SvgIcon class="h-5 w-5" name="frame" />
              </div>
            </div>
          </div>
          <div
            class="rounded-lg border border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-900"
          >
            <div class="p-2">
              <div class="flex items-center">
                <SvgIcon class="h-5 w-5" name="expand" />
              </div>
            </div>
          </div>
          <div
            class="rounded-lg border border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-900"
          >
            <div class="p-2">
              <div class="flex items-center">
                <SvgIcon class="h-5 w-5" name="eye-1" />
              </div>
            </div>
          </div>
                    <div
            class="rounded-lg border border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-900"
          >
            <div class="p-2">
              <div class="flex items-center">
                <SvgIcon class="h-5 w-5" name="warning" />
              </div>
            </div>
          </div>
          <div
            class="rounded-lg border border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-900"
          >
            <div class="p-2">
              <div class="flex items-center">
                <SvgIcon class="h-5 w-5" name="show-more" />
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import SvgIcon from '@/components/SvgIcon.vue'
import { useStore } from 'vuex'
import { pauseSchedule, postExecution } from '@/apis/workflows'
import { ref, computed } from 'vue'
import Popper from 'vue3-popper'
import Spinner from '../Spinner.vue'
import { updateUserDetail } from '@/apis/user'

const props = defineProps({
  workflowId: String,
  isActive: Boolean,
  isScheduled: Boolean,
  validate: Function,
  desktopDataSelected: Object,
  nodes: Array,
  workflowData: Object
})

const store = useStore()
const emit = defineEmits([
  'response',
  'show-schedule-modal',
  'show-history',
  'error'
])
const showRunLoader = ref(false)

const currentIsActive = computed(() => {
  return (
    props.isActive ||
    (props.isScheduled && props.workflowData.isJobAddedOnQueue)
  )
})

/**
 * Computed property that determines if an icon should be displayed based on:
 * - Presence of an "Incoming Webhook" node in props
 * - Whether the user's email or phone is unverified
 *
 * @returns {boolean} True if the icon should be shown, false otherwise.
 */
const shouldShowIcon = computed(() => {
  const { userData } = store.state.user

  return (
    (props.nodes.length > 0 && props.nodes[0].label === 'Incoming Webhook') ||
    !userData.isEmailVerified ||
    !userData.isPhoneVerified
  )
})

/**
 * Computed property that returns a message based on the user's account verification status
 * and the current workflow configuration.
 *
 * @returns {string} Message indicating next steps for the user, including:
 * - Requesting account verification if email or phone is not verified.
 * - Informing the user to trigger the webhook if the workflow is set up with an "Incoming Webhook".
 * - Default message to "Run Workflow" when both verification and configuration are complete.
 */
const workflowMessage = computed(() => {
  const { userData } = store.state.user

  if (!userData.isEmailVerified || !userData.isPhoneVerified) {
    return 'Please Verify Your Account To Run Workflows'
  }

  if (props.nodes.length > 0 && props.nodes[0].label === 'Incoming Webhook') {
    return 'You’ve configured the workflow using incoming webhook, so you’ve to hit webhook to launch the workflow.'
  }

  return 'Run Workflow'
})

const currentIsScheduled = computed(() => {
  return props.isScheduled
})

const onClickRun = async () => {
  if (props.validate()) return
  try {
    showRunLoader.value = true
    let response
    if (currentIsActive.value) {
      response = await pauseSchedule(props.workflowId)
      if (response['success']) {
        emit('response', 'Execution paused', response['data'])
      }
    } else {
      response = await postExecution(props.workflowId)
      if (response['success']) {
        //check if this is user first workflow run
        if (
          store.getters['user/getUserData'].firstWorkflowRun !== undefined &&
          store.getters['user/getUserData'].firstWorkflowRun === false
        ) {
          const res = await updateUserDetail({ firstWorkflowRun: true })
          if (res['success']) {
            store.dispatch('user/updateUserDataWorkflow', {
              firstWorkflowRun: true
            })
          }
        }
        emit('response', 'Automation started', response['data'])
      } else {
        throw response.message
      }
    }
  } catch (error) {
    emit('error', error)
  }
  showRunLoader.value = false
}
</script>

<style></style>
