import axios from '@/common/axios'

/**
 * Fetches accounts with analytics enabled.
 * @returns {Promise<Object>} - The response data from the API call.
 */
const fetchEnabledAnalytics = async () => {
  try {
    const response = await axios.get(`/accounts?analyticsEnabled=true`)
    return response.data
  } catch (error) {
    return handleError(error, 'An error occurred while retreiving accounts.')
  }
}

/**
 * Enables analytics for a specific account.
 * @param {string} accountId - The ID of the account to enable analytics for.
 * @returns {Promise<Object>} - The response data from the API call.
 */
const enableAnalyticsForAccount = async accountId => {
  try {
    const response = await axios.put(`/accounts/${accountId}`, {
      analyticsEnabled: true
    })
    return response.data
  } catch (error) {
    return handleError(error, 'An error occurred while enabling analytics.')
  }
}

/**
 * Handles API errors, providing fallback error data.
 * @param {Object} error - The error object caught from the Axios request.
 * @param {String} fallbackMessage - A fallback message to return if the error response is unavailable.
 * @returns {Object|String} - The error response data or a fallback message.
 */
const handleError = (error, fallbackMessage) => {
  return error?.response?.data || { message: fallbackMessage }
}

export { fetchEnabledAnalytics, enableAnalyticsForAccount }
