<template>
  <div
    class="fixed left-0 top-0 z-40 flex h-screen w-screen items-center justify-center"
  >
    <div
      class="absolute left-0 top-0 h-screen w-screen bg-gray-400 bg-opacity-40"
      @click="$emit('modal-close')"
    />
    <div
      class="z-10 flex w-[720px] flex-col rounded-[10px] bg-white px-8 py-10"
    >
      <div class="relative mb-9">
        <nav class="flex justify-center space-x-4" aria-label="Tabs">
          <a
            href="#"
            title=""
            class="rounded-lg bg-gray-100 px-3 py-2 text-sm font-medium text-gray-700 transition-all duration-200 dark:bg-gray-700 dark:text-gray-50"
            aria-current="page"
          >
            Scheduling
          </a>
        </nav>
        <button
          type="button"
          class="absolute right-0 top-0 h-9 w-9 rounded-lg border border-gray-200 bg-white p-1.5 text-gray-600 transition-all duration-200 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50 dark:focus:ring-offset-gray-900"
          @click="$emit('modal-close')"
        >
          <span class="sr-only"> Close </span>
          <SvgIcon name="close" class="m-auto" />
        </button>
      </div>
      <div class="flex flex-col h-full">
        <Spinner v-if="loadingInputs" class="mx-auto" />

        <div
          v-if="
            props.firstNode?.label === 'Sheet Input' ||
            props.firstNode?.label === 'Get data from csv'
          "
          class="mb-2"
        >
          <p class="mb-2 font-[400]">
            You've used
            {{
              props.firstNode?.label === 'Sheet Input' ? 'google sheet' : 'CSV'
            }}
            as input for workflow so we'll be automatically detecting the no. of
            rows to be processed everyday depending on daily limits and then
            <span class="font-semibold">we'll schedule it</span> from our side
            to run on each day until it process all the rows of sheet/CSV.
          </p>
          <Button
            text="Advanced Settings"
            :color="advancedButtonClicked ? 'primary' : 'tertiary'"
            size="small"
            leftIcon="plus"
            @click="advancedButtonClicked = true"
          />
        </div>
        <div v-if="showModal" class="flex flex-col flex-grow">
          <div class="py-4" v-if="props.firstNode?.label === 'Sheet Input'">
            <div class="mb-4 rounded-md bg-yellow-50 p-4">
              <div class="flex">
                <div class="flex-shrink-0">
                  <svg
                    class="h-5 w-5 text-yellow-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div class="ml-3">
                  <p class="text-sm text-yellow-700">
                    Watch Row feature will be activated after enabling Watch Row
                    toggle and saving a schedule
                  </p>
                </div>
              </div>
            </div>
            <Switch
              v-model="watchRowToggle"
              label="Watch Row"
              caption="if this enable then wherever we found a new row in your sheet we will run the workflow as per your time interval"
            />
          </div>
          <Scheduling
            v-if="!loadingInputs"
            :inputs="inputs"
            :option="option"
            :trigger-validation="triggerValidation"
            @input-update="updateInputs"
            @option-update="updateOption"
            @validationSuccess="saveInputs"
            @validationFailed="triggerValidation = false"
          />

          <div class="mt-auto pt-4">
            <Button
              v-if="!loadingInputs"
              @click="triggerValidation = true"
              class="self-start"
            >
              Save
              <Spinner v-show="saving" size="small" class="ml-1 text-white" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { updateWorkflow } from '@/apis/workflows'
import Button from '@/components/Button.vue'
import Scheduling from '@/components/scheduling/config.vue'
import { convertToProperFormat } from '@/components/scheduling/utility'
import Spinner from '@/components/Spinner.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { amplitudeTrackEvent } from '@/common/functions/eventTracker'
import Switch from '@/components/Switch.vue'

const props = defineProps({ workflow: {}, firstNode: {} })
const inputs = ref(props.workflow?.schedule?.rawData || {})
const option = ref(props.workflow?.schedule?.option || 0)
const saving = ref(false)
const triggerValidation = ref(false)
const advancedButtonClicked = ref(false)
const watchRowToggle = ref(props.workflow?.isWatchRowEnabled || false)

onMounted(() => {
  if (
    props.workflow?.schedule &&
    Object.keys(props.workflow.schedule).length > 0
  ) {
    advancedButtonClicked.value = true
  }
})

const showModal = computed(() => {
  if (
    props.firstNode?.label === 'Sheet Input' ||
    props.firstNode?.label === 'Get data from csv'
  ) {
    if (advancedButtonClicked.value === true) {
      return true
    } else {
      return false
    }
  } else {
    return true
  }
})

const route = useRoute()

const emit = defineEmits(['success', 'error'])

const updateInputs = updatedInputs => {
  inputs.value = updatedInputs
  if (inputs.value?.endDate) {
    const date = new Date(inputs.value?.endDate)
    const today = new Date()
    let obj = { ...updatedInputs }
    if (
      date.getUTCFullYear() === today.getUTCFullYear() &&
      date.getUTCMonth() === today.getUTCMonth() &&
      date.getUTCDate() === today.getUTCDate()
    ) {
      // Set the time to 23:59:59
      const newEndDate = new Date()
      newEndDate.setHours(23, 59, 59, 0)
      obj.endDate = newEndDate.toISOString()
      inputs.value = obj
    }
  }
}
const updateOption = newOption => {
  option.value = newOption
}

const saveInputs = async () => {
  if (watchRowToggle.value && (option.value === 0 || option.value === '')) {
    triggerValidation.value = false
    emit('error', 'Please select a valid schedule option')
    return
  }
  saving.value = true
  try {
    let optionVal = option.value === '' ? 0 : option.value
    triggerValidation.value = false
    const structuredInput = convertToProperFormat(inputs.value, optionVal)
    const optionSchedulingData = {
      startDate: inputs.value.startDate,
      endDate: inputs.value.endDate,
      startTime: inputs.value.startTime,
      endTime: inputs.value.endTime,
      option: optionVal,
      timezone: Intl?.DateTimeFormat().resolvedOptions().timeZone
    }
    amplitudeTrackEvent('Workflow Schedule', localStorage.getItem('email'), {
      schedule: {
        ...structuredInput,
        ...optionSchedulingData,
        rawData: inputs.value
      },
      workflowId: route.params.id
    })
    await updateWorkflow(route.params.id, {
      schedule: {
        ...structuredInput,
        ...optionSchedulingData,
        rawData: inputs.value
      },
      isScheduled:
        optionSchedulingData.option === 0
          ? false
          : watchRowToggle.value
          ? false
          : true,
      isActive: false,
      isWatchRowEnabled: watchRowToggle.value
    })
    emit(
      'success',
      watchRowToggle.value
        ? 'Watch Row Updated Successfully'
        : 'Schedule updated successfully'
    )
  } catch (error) {
    emit(
      'error',
      'Failed to add or update the schedule. Please try again in some time.'
    )
  }
  saving.value = false
}
</script>
