<template>
  <div
    class="h-full overflow-x-hidden bg-white font-sans text-gray-900 antialiased dark:bg-gray-900 dark:text-gray-50"
  >
    <!-- START WRAPPER -->
    <div class="flex min-h-screen items-stretch justify-between">
      <!-- START LEFT COLUMN -->
      <OnboardingLeftColumn />
      <!-- END LEFT COLUMN -->

      <!-- START RIGHT COLUMN -->
      <div class="flex-1 bg-white dark:bg-gray-900">
        <div class="grid h-full items-start justify-center">
          <div class="h-full px-6 py-16 sm:px-12 sm:py-20 lg:px-20">
            <div class="flex h-full max-w-3xl flex-col">
              <a href="#" title="TexAu" class="">
                <img
                  class="mx-auto h-12 w-auto xl:h-14"
                  src="@/assets/images/logo-alt.png"
                  alt="TexAu logo"
                />
              </a>

              <div class="mt-8 grid h-full place-items-center">
                <div>
                  <div class="text-center">
                    <Heading text="Tell us a little more about you!" />
                  </div>

                  <ValidationForm
                    class="mt-4 grid grid-cols-2 gap-y-5 gap-x-8"
                    :platformInputs="platformInputs"
                    :previousNodes="[]"
                    :inputs="inputData"
                    :triggerValidation="triggerValidation"
                    @validationSuccess="submitCreate"
                    @validationFailed="triggerValidation = false"
                  />
                  <div class="mt-5 flex gap-5">
                    <Button
                      class=""
                      :show-loader="isLoadingSkip"
                      @click="skipOnboarding"
                      text="Skip"
                      rightIcon="skip"
                    />
                    <Button
                      color="tertiary"
                      :show-loader="isLoading"
                      @click="triggerValidation = true"
                      text="Next"
                      rightIcon="right-arrow"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END RIGHT COLUMN -->
    </div>
    <!-- END WRAPPER -->
  </div>
</template>

<script>
import { updateUserDetail } from '@/apis/user'
import { updateOrganisationDetails } from '@/apis/workspace'
import arrow from '@/assets/images/onboarding/illustrations/right-arrow-icon.svg'
import UserProfile from '@/common/userWrapper'
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
import Heading from '@/components/onboarding/Heading.vue'
import OnboardingLeftColumn from '@/components/onboarding/OnboardingLeftColumn.vue'
import Select from '@/components/Select.vue'
import ValidationForm from '@/components/ValidationForm.vue'
import { amplitudeTrackEvent } from '@/common/functions/eventTracker'

export default {
  // step-2
  name: 'addAdditionalDetails',
  components: {
    OnboardingLeftColumn,
    Heading,
    Select,
    Button,
    Input,
    ValidationForm
  },
  data() {
    return {
      arrow,
      platformInputs: null,
      isLoading: false,
      isLoadingSkip: false,
      triggerValidation: false
    }
  },
  created() {
    // this.inputData = {}
    this.platformInputs = [
      {
        isRequired: true,
        isInput: true,
        type: 'text',
        placeholder: 'First Name',
        label: 'First Name',
        name: 'firstName'
      },
      {
        isRequired: true,
        isInput: true,
        type: 'text',
        placeholder: 'Last Name',
        label: 'Last Name',
        name: 'lastName'
      },
      {
        name: 'role',
        type: 'select',
        label: `What's your role/occupation?`,
        isRequired: true,
        choices: [
          { value: 'CEO', label: 'CEO' },
          { value: 'CTO', label: 'CTO' },
          { value: 'Agency owner', label: 'Agency owner' },
          { value: 'Product', label: 'Product' },
          { value: 'Growth', label: 'Growth' }
        ]
      },
      {
        name: 'companySize',
        type: 'select',
        label: `What's your company size?`,
        isRequired: true,
        choices: [
          { value: '1-10', label: '1-10' },
          { value: '10-50', label: '10-50' },
          { value: '50+', label: '50+' }
        ]
      },

      {
        isRequired: true,
        isInput: true,
        type: 'text',
        placeholder: 'Previous Experience',
        label: 'What is your goal using TexAu?',
        name: 'goal'
      },
      {
        isRequired: true,
        isInput: true,
        type: 'text',
        placeholder: 'Company Usp',
        label: `What does your company sell?`,
        name: 'companyUsp'
      },
      {
        name: 'prevExp',
        type: 'select',
        label: `Any previous experience with automation and scraping tools?`,
        isRequired: true,

        choices: [
          { value: 'Phantombuster', label: 'Phantombuster' },
          { value: 'Expandi', label: 'Expandi' },
          { value: 'Dux Soup', label: 'Dux Soup' },
          { value: 'Other', label: 'Other' }
        ]
      },
      {
        name: 'mainSocial',
        type: 'select',
        label: `What's your main social platform you would like to automate?`,
        isRequired: true,

        choices: [
          { value: 'LinkedIn', label: 'LinkedIn' },
          { value: 'Twitter', label: 'Twitter' },
          { value: 'Instagram', label: 'Instagram' },
          { value: 'Facebook', label: 'Facebook' },
          { value: 'Github', label: 'Github' },
          { value: 'Crunchbase', label: 'Crunchbase' },
          { value: 'Google', label: 'Google' },
          { value: 'Youtube', label: 'Youtube' },
          { value: 'Slack', label: 'Slack' },
          { value: 'Reddit', label: 'Reddit' },
          { value: 'Producthunt', label: 'Producthunt' }
        ]
      },
      {
        isRequired: true,
        isInput: true,
        type: 'text',
        placeholder: 'Company Description',
        label:
          'What is your company about? Describe your company activity in one sentence',
        name: 'companyDescription'
      }
    ]
  },
  methods: {
    async updateNextStep() {
      const responseUser = await updateUserDetail({
        currentOnboardingStep: 'Install Extension'
      })
      UserProfile.updateOnboardingStateInCookies('Install Extension')
      this.isLoading = false
      if (responseUser['success']) {
        await this.$router.push('/onboarding/install-extension')
      } else {
        this.$emit('error', 'Error in moving to next step.')
      }
    },

    async submitCreate(inputs) {
      this.triggerValidation = false

      this.isLoading = true

      const organisationId = window.$cookies.get('organisationId')

      const userResponse = await updateUserDetail({
        firstname: inputs.firstName,
        lastname: inputs.lastName
      })
      const response = await updateOrganisationDetails(organisationId, {
        ownerTitle: inputs.role,
        companySize: inputs.companySize,
        knownTools: inputs.prevExp,
        platformsInterestedIn: inputs.mainSocial,
        useTexAuFor: inputs.goal,
        companyDescription: inputs.companyDescription,
        companyUsp: inputs.companyUsp
      })
      if (response['success'] && userResponse['success']) {
        await this.updateNextStep()
      } else {
        this.$emit('error', 'Error Occurred!')
      }
    },
    /**
     * Skips the onboarding process for the user.
     * This method updates the user's details to indicate that they have skipped onboarding,
     * sets the user as onboarded, and tracks the event for analytics.
     *
     * @async
     * @function skipOnboarding
     * @returns {Promise<void>} A promise that resolves when the onboarding is skipped.
     * @throws {Error} Emits an error if the update fails.
     */
    async skipOnboarding() {
      this.isLoadingSkip = true
      const userResponse = await updateUserDetail({
        isOnboarded: true,
        skipedOnboardingAt: new Date().toISOString()
      })
      if (userResponse['success']) {
        let user_ = UserProfile.getUser()
        user_['isOnboarded'] = true
        delete user_['currentOnboardingStep']
        UserProfile.setUser(user_)
        amplitudeTrackEvent(
          'Sing-up Completed via skip onboarding',
          localStorage.getItem('email'),
          {
            signupMethod: 'Email'
          }
        )
        await this.$router.go()
      } else {
        this.$emit('error', 'Error Occurred!')
      }
    }
  }
}
</script>

<style></style>
