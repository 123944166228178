<template>
  <!-- <div
    class="h-full overflow-hidden bg-white font-sans text-gray-900 antialiased dark:bg-gray-900 dark:text-gray-50"
  > -->
  <!-- START WRAPPER -->
  <!-- <div class="flex h-full flex-col dark:bg-gray-900"> -->
  <!-- <div class="flex min-h-0 flex-1 overflow-hidden"> -->
  <!-- START SIDEBAR -->
  <Sidebar activeOption="preference" />
  <!-- END SIDEBAR -->

  <!-- START MAIN CONTENT -->
  <div
    class="flex min-w-0 flex-1 flex-col"
    :class="[{ 'ml-[57.6px]': !isExpandedDefault }]"
  >
    <Header />
    <!-- <div class="h-[100px]" /> -->
    <slot :error="onError" :success="onSuccess" :warning="onWarning" />
  </div>
  <!-- END MAIN CONTENT -->
  <!-- </div> -->
  <!-- </div> -->
  <!-- END WRAPPER -->
  <ToastContainer class="w-1/5" ref="toast" />
  <!-- </div> -->
</template>

<script>
import { getMetaDataFromApi } from '@/apis/metadata'
import Header from '@/components/layout/Header.vue'
import Sidebar from '@/components/layout/Sidebar.vue'
import ToastContainer from '@/components/ToastContainer.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import { constants } from '@/common/constants'
import CryptoJS from 'crypto-js'

export default {
  name: 'LayoutDefault',
  components: {
    Sidebar,
    ToastContainer,
    Header
  },
  async mounted() {
    try {
      const response = await getMetaDataFromApi()
      await this.saveMetadata({ payload: response.data })
      let roleName = this.getRole(response.data.roles)
      await this.setUserRole({ payload: roleName })

      window.intercomSettings = {
        app_id: constants.intercomAppid,
        vertical_padding: 70
      }
      var hash = CryptoJS.HmacSHA256(
        this.userData._id,
        constants.Intercom_Secret_key
      ).toString()

      window.Intercom('boot', {
        app_id: constants.intercomAppid,
        user_id: this.userData._id,
        user_hash: hash,
        email: this.userData.email,
        name: this.userData.firstname
          ? this.userData.lastname
            ? `${this.userData.firstname} ${this.userData.lastname}`
            : this.userData.firstname
          : this.userData.lastname
          ? this.userData.lastname
          : 'TexAu User',
        firstName: this.userData?.firstname ?? 'TexAu',
        lastName: this.userData?.lastname ?? 'User',
        organisation_id: this.userData?.organisationId,
        plan_id: this.userData?.planId,
        planPayment_id: this.userData?.planPaymentId,
        created_at: this.userData?.createdAt,
        firstAutomation: this.userData?.firstAutomationRun || false,
        firstWorkflow: this.userData?.firstWorkflowRun || false,
        execution_time_left_in_ms: this.additionalData.executionTimeLeftInMs,
        total_execution_time_in_ms: this.additionalData.executionTimeInMs,
        data_enrichments_left: this.emailEnrichmentsLeft,
        total_data_enrichments: this.additionalData.totalEmailEnrichment,
        api_access: this.apiAccess,
        integration_access: this.integrationsAccess,
        is_email_verified: this.userData.isEmailVerified,
        is_phone_verified: this.userData.isPhoneVerified
      })
    } catch (error) {
      this.onError('Failed to fetch metadata!')
    }
  },
  computed: {
    ...mapState('user', ['isExpandedDefault']),
    ...mapState('user', ['executionTimeLeft']),
    ...mapState('user', ['emailEnrichmentsLeft']),
    ...mapState('user', ['apiAccess']),
    ...mapState('user', ['userData']),
    ...mapState('user', ['userWorkspaceData']),
    ...mapState('user', ['integrationsAccess']),
    ...mapState('user', ['additionalData'])
  },
  methods: {
    ...mapActions('settings', ['saveMetadata']),
    ...mapActions('settings', ['setUserRole']),
    ...mapGetters('settings', ['getMetaData']),

    getRole(rolesArr) {
      let roleName
      let obj = this.userWorkspaceData.find(
        item => item.workspaceId === window.$cookies.get('workspaceId')
      )
      for (const role of rolesArr) {
        if (role.id === obj.roleId) {
          roleName = role.name
          break
        }
      }
      return roleName
    },

    onError(data) {
      this.$refs.toast.addToast({
        timeout: 4000,
        text: 'Error Occurred!',
        color: 'error',
        caption: data,
        action: false,
        close: true,
        icon: true
      })
    },
    onWarning(data) {
      this.$refs.toast.addToast({
        timeout: 4000,
        text: 'Warning!',
        color: 'warning',
        caption: data,
        action: false,
        close: true
      })
    },

    onSuccess(data) {
      this.$refs.toast.addToast({
        timeout: 4000,
        text: 'Successful!',
        color: 'positive',
        caption: data,
        action: false,
        close: true,
        icon: true
      })
    }
  }
}
</script>

<style scoped></style>
