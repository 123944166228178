export function preventFraming() {
  if (window.top !== window.self) {
    // Create and style an error message
    const errorDiv = document.createElement('div')
    errorDiv.style.cssText = `
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f8f9fa;
        color: #721c24;
        font-family: Arial, sans-serif;
        font-size: 16px;
        padding: 20px;
        text-align: center;
      `

    // Set the error message
    errorDiv.innerHTML = `
        <div>
          <strong>${window.location.host}</strong> refused to connect.<br>
          This website does not allow viewing in an iframe.
        </div>
      `

    // Clear existing content and show error
    document.body.innerHTML = ''
    document.body.appendChild(errorDiv)

    // Stop further execution
    throw new Error('Frame access denied')
  }
}
