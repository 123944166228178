<template>
  <div class="px-4 h-full pb-5 pt-2 sm:px-5">
    <Table class="relative h-full min-w-full">
      <TableHeader />
      <TableBody
        @modal="(type, data) => $emit('modal', type, data)"
        :rows="tableData"
        :proxyData="proxyData"
        :desktopData="desktopData"
        @apiError="data => $emit('apiError', data)"
        @proxyEmit="proxyEmit"
        @showForceDeleteModal="data => $emit('showForceDeleteModal', data)"
        @fetchAllConnectedAccounts="() => $emit('fetchAllConnectedAccounts')"
      />
    </Table>
  </div>
  <button class="hidden" id="v2-refresh-page" @click="$router.go()">
    refresh
  </button>
</template>

<script>
import Table from './Table.vue'
import TableHeader from './TableHeader.vue'
import TableBody from './TableBody.vue'
import Button from '@/components/Button.vue'

export default {
  name: 'WorkspaceManagerTable',
  components: { TableHeader, TableBody, Table, Button },
  props: {
    tableData: { type: Array, default: [] },
    proxyData: { type: Array, default: [] },
    desktopData: { type: Array, default: [] }
  },
  methods: {
    proxyEmit(data) {
      this.$emit('proxyChangeResponse', data)
    },
    onClick() {
      this.$router.replace({ query: null })
      this.$emit('showPlatforms', 'platforms')
    },
    async modalToggle(e) {
      let item
      if (this.integrationData.auth.type === 'OAuth2') {
        this.addOauthAccount(this.integrationData.name)
      } else if (this.integrationData.auth.type === 'apiKey') {
        if (this.accountIdForUpdateApi) {
          item = {
            _id: this.integrationData._id,
            name: this.integrationData.name,
            type: this.integrationData.type,
            label: this.integrationData.label,
            logoUrl: this.integrationData.logoUrl,
            update: true,
            accountId: this.accountIdForUpdateApi,
            accountName: this.accountNameForUpdateApi
          }
        } else {
          item = {
            _id: this.integrationData._id,
            name: this.integrationData.name,
            type: this.integrationData.type,
            logoUrl: this.integrationData.logoUrl,
            label: this.integrationData.label,
            update: false
          }
        }

        let val = true
        this.$emit('modalActivation', { item, val })
        this.accountIdForUpdateApi = null
        this.accountNameForUpdateApi = null
      } else {
        item = {
          _id: this.integrationData._id,
          name: this.integrationData.name,
          type: this.integrationData.type,
          logoUrl: this.integrationData.logoUrl,
          label: this.integrationData.label,
          auth: this.integrationData.auth
        }
        let val = true
        this.$emit('modalActivation', { item, val })
      }
    }
  }
}
</script>
