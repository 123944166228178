<template>
  <DesktopAddModal
    v-if="modalOpen"
    @close="close()"
    @error="
      e => {
        emitError(e)
      }
    "
    @success="
      e => {
        emitSuccess(e)
      }
    "
  />
  <DesktopDownloadModal
    v-if="openDownloadModal"
    @close="openDownloadModal = false"
  />
  <AutomationHeader title="Desktop" />
  <main
    class="flex min-h-0 min-w-0 flex-1 border-t border-gray-200 dark:border-gray-700"
  >
    <SettingsMenu active-option="Desktop" />

    <div class="relative flex-1 border-l border-gray-200 dark:border-gray-700">
      <div class="p-2">
        <div
          v-if="!desktopUnlimited"
          class="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4"
          role="alert"
        >
          <p>
            <span class="font-bold">Notice:</span>
            Running automation via desktop app will still consume your cloud run
            time.
          </p>
        </div>
      </div>
      <div class="flex items-center px-4 sm:px-5 pt-2 justify-between">
        <p
          @click="openDownloadModal = true"
          class="text-blue-500 font-[500] cursor-pointer"
        >
          Get Desktop App for Your System
        </p>

        <Button
          v-if="userRole !== 'viewer'"
          text="Add New"
          leftIcon="plus"
          class="h-[33px]"
          @click="modalOpen = true"
        />
      </div>

      <div class="px-4 py-5 sm:p-5">
        <div
          v-if="noData"
          class="mt-10 flex w-full flex-col items-center justify-center space-y-4"
        >
          <SvgIcon name="empty-data-light" />
          <p class="text-3xl font-bold text-gray-900 dark:text-gray-50">
            No Desktop Available
          </p>
        </div>
        <div v-else>
          <div class="overflow-hidden">
            <table class="min-w-full">
              <thead class="bg-gray-50 dark:bg-gray-700">
                <tr>
                  <th
                    scope="col"
                    class="rounded-l-lg px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="rounded-l-lg px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                  >
                    Connected
                  </th>
                  <th
                    scope="col"
                    class="rounded-l-lg px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                  >
                    Registered
                  </th>
                  <th scope="col" class="rounded-r-lg px-3 py-3.5 text-left">
                    <span class="sr-only"> Actions </span>
                  </th>
                </tr>
              </thead>
              <tbody
                v-if="loading"
                class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
              >
                <tr v-for="ele in 4" :key="ele">
                  <td
                    v-for="ele in 3"
                    :key="ele"
                    class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
                  >
                    <Skeleton
                      height="40px"
                      width="140px"
                      borderRadius="8px"
                    ></Skeleton>
                  </td>
                </tr>
              </tbody>
              <tbody
                v-else
                class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
              >
                <tr v-for="(data, index) in tableData" :key="data._id">
                  <td
                    class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
                  >
                    {{ data.name }}
                  </td>

                  <td class="whitespace-nowrap px-3 py-4">
                    <div class="flex items-center">
                      <Badge
                        v-if="data.isConnected"
                        text="Connected"
                        color="added"
                      />
                      <Badge v-else text="Not Connected" color="removed" />
                    </div>
                  </td>

                  <td class="whitespace-nowrap px-3 py-4">
                    <div class="flex items-center">
                      <Badge
                        v-if="data.isRegistered"
                        text="Registered"
                        color="added"
                      />
                      <Badge v-else text="Not Registered" color="removed" />
                    </div>
                  </td>
                  <td
                    v-if="userRole !== 'viewer'"
                    class="whitespace-nowrap w-[300px] px-3 py-4"
                  >
                    <div class="flex items-center justify-end space-x-3 px-10">
                      <Button
                        v-if="reallyDeleteIndex !== index"
                        icon="delete"
                        color="iconOnly"
                        @click="reallyDeleteIndex = index"
                        class="-m-2 rounded-lg p-2 text-red-600 transition-all duration-200 hover:bg-red-50 focus:outline-none dark:text-red-400 dark:hover:bg-red-500 dark:hover:text-red-50"
                      />
                      <div
                        v-else
                        class="flex items-center justify-center gap-x-3"
                      >
                        <p class="font-semibold text-red-600">
                          Really Delete ?
                        </p>
                        <Button
                          icon="delete"
                          color="iconOnly"
                          @click="onDeleteClick(data._id, index, data.name)"
                          class="-m-2 rounded-lg p-2 text-red-600 transition-all duration-200 hover:bg-red-50 focus:outline-none dark:text-red-400 dark:hover:bg-red-500 dark:hover:text-red-50"
                        />
                        <Button
                          icon="close-1"
                          color="iconOnly"
                          @click="reallyDeleteIndex = null"
                          class="-m-2 rounded-lg p-2 text-gray-600 transition-all duration-200 hover:bg-gray-100 focus:outline-none"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {
  getDesktop,
  deleteDesktop,
  renameDesktop
} from '@/apis/settings/desktop'
import Button from '@/components/Button.vue'
import SettingsMenu from '@/components/settings/settingsMenu.vue'
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import DesktopAddModal from '@/components/settings/desktop/DesktopAddModal.vue'
import DesktopDownloadModal from '@/components/settings/desktop/DesktopDownloadModal.vue'
import Badge from '@/components/Badge.vue'
import { mapState } from 'vuex'
import NoAccess from '@/components/NoAccess.vue'

export default {
  name: 'DesktopMain',
  components: {
    Button,
    SettingsMenu,
    AutomationHeader,
    SvgIcon,
    DesktopAddModal,
    Badge,
    NoAccess,
    DesktopDownloadModal
  },
  data() {
    return {
      loading: false,
      noData: false,
      modalOpen: false,
      tableData: null,
      reallyDeleteIndex: null,
      poll: true,
      openDownloadModal: false
    }
  },
  async mounted() {
    await this.getAllDesktop()
    this.fetchPollingData()
  },
  beforeUnmount() {
    this.poll = false
  },
  computed: {
    ...mapState('user', ['desktopUnlimited']),
    ...mapState('settings', ['userRole'])
  },
  methods: {
    async getAllDesktop() {
      try {
        this.loading = true
        const response = await getDesktop()
        if (response.data.length > 0) {
          this.noData = false
          this.tableData = response.data
        } else {
          this.noData = true
        }
      } catch (error) {
        this.emitError(error)
      } finally {
        this.loading = false
      }
    },
    async fetchPollingData() {
      const response = await getDesktop()
      if (response.data.length > 0) {
        this.noData = false
        this.tableData = response.data
      } else {
        this.noData = true
      }
      if (this.poll) {
        setTimeout(() => {
          this.fetchPollingData()
        }, 5000)
      }
    },
    async onDeleteClick(id, index, name) {
      try {
        const response = await deleteDesktop(id)
        if (response['success']) {
          this.tableData.splice(index, 1)
          if (this.tableData.length === 0) {
            this.noData = true
          }
          this.emitSuccess(`${name} Removed Successfully`)
        } else {
          throw response.message
        }
      } catch (error) {
        this.emitError(error)
      } finally {
        this.reallyDeleteIndex = null
      }
    },
    emitError(msg) {
      this.$emit('error', msg)
    },
    emitSuccess(msg) {
      this.$emit('success', msg)
    },
    close() {
      this.modalOpen = false
      this.getAllDesktop()
    }
  }
}
</script>

<style scoped></style>
