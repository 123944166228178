<template>
  <tbody
    class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
  >
    <tr v-for="(rowData, mainIndex) in tableRows" :key="componentKey">
      <td v-for="(data, index) in rowData" class="whitespace-nowrap px-3 py-4">
        <div class="flex gap-1" v-if="index === 0">
          <Entity
            removePadding
            :placeholder="data.name"
            :initials="data.initials"
            :caption="data.caption"
            :imgUrl="
              data.profilePictureUrl &&
              data.profilePictureUrl.startsWith('https')
                ? data.profilePictureUrl
                : imgUrl
            "
          />
          <div
            v-if="usingDeveloperMode"
            class="flex cursor-pointer items-center justify-center"
          >
            <Popper hover>
              <SvgIcon
                @click="copyAccountId(data.id)"
                class="text-blue-500"
                name="copy"
              />
              <template #content>
                <div class="w-max max-w-lg rounded bg-blue-50 p-2 text-sm">
                  Copy Account ID
                </div>
              </template>
            </Popper>
          </div>
          <div
            v-if="showLiPremium(data)"
            class="flex items-center justify-center"
          >
            <Popper hover>
              <div
                class="h-5 w-5 rounded-md relative overflow-hidden border-1 border-white"
              >
                <img
                  class="object-cover"
                  src="https://assets-of-v2.s3.amazonaws.com/platforms/logos/li_premium_sq.svg"
                  alt=""
                />
              </div>
              <template #content>
                <div class="w-max max-w-lg rounded bg-blue-50 p-2 text-sm">
                  LinkedIn Premium
                </div>
              </template>
            </Popper>
          </div>
        </div>
        <div
          v-if="data?.hasOwnProperty('readyToUse') && data.readyToUse === false"
          class="flex w-fit items-center mt-2 p-2 bg-blue-50 border border-blue-200 rounded-md shadow-sm"
        >
          <Spinner size="small" class="mr-2 text-blue-500" />
          <span class="text-xs font-medium text-gray-700">
            Account creation is in progress...
          </span>
        </div>

        <div v-else-if="index === 1" class="flex -space-x-2 overflow-hidden">
          <img
            v-for="img in data.url"
            class="h-8 w-8 shrink-0 ring-2 ring-white rounded-full object-cover"
            :src="img"
          />
        </div>
        <div class="min-w-[205px] h-[41.6px]" v-else-if="index === 2">
          <!-- TODO: Might need later -->
          <!-- :options="[
              {
                Proxy: data.options,
                HeaderIcon: 'server',
                lock: checkLockStatus(
                  rowData[7].providedByTexau,
                  rowData[7].activeProxyName,
                  rowData[7].desktopNames
                )
              }, -->
          <GroupSelect
            :class="[{ 'pointer-events-none': userRole === 'viewer' }]"
            @change="
              e =>
                checkChangeProxy(
                  e,
                  rowData[0].id,
                  mainIndex,
                  rowData[7].activeProxyName
                )
            "
            :options="[
              {
                Proxy: data.options,
                HeaderIcon: 'server'
              },
              {
                Desktop: rowData[7].desktopNames,
                HeaderIcon: 'desktop'
              }
            ]"
            :flagUrl="getFlag(rowData[7].activeProxyLocationFlag)"
            :modelValue="rowData[7].activeProxyName"
            :hide-clear="rowData[7].providedByTexau"
          />
        </div>
        <span
          v-else-if="index === 3"
          class="text-sm text-gray-900 dark:text-gray-50"
        >
          {{ data.country }}
        </span>
        <span
          v-else-if="index === 4"
          class="text-sm text-gray-900 dark:text-gray-50"
        >
          {{ `${formatDate(data.updatedAt)}` }}
        </span>

        <div
          v-else-if="index === 5"
          class="flex items-center justify-left ml-[1vh]"
        >
          <a :href="data.account" target="_blank">
            <div class="h-10 w-10">
              <Button color="iconOnly" icon="external-link" />
            </div>
          </a>
        </div>
        <div
          v-else-if="index === 6"
          class="flex items-center justify-left ml-[2vh]"
        >
          <StatusDot :color="data.active ? 'positive' : 'error'" size="small" />
        </div>
        <div
          v-else-if="index === 7"
          class="flex items-center justify-left ml-[2vh]"
        >
          <Popper
            v-if="
              checkProxyName(
                data.activeProxyName,
                data.activeProxy,
                data.providedByTexau
              )
            "
            hover
            class="cursor-pointer"
          >
            <div
              @click="refreshProxy(data.activeProxyName)"
              class="flex gap-1 bg-gray-200 border-[1px] p-1 rounded-lg"
            >
              <StatusDot
                :color="data.activeProxy ? 'positive' : 'error'"
                size="small"
              />

              <svg
                v-if="showLoadingSpinner"
                class="text-blue-600 dark:text-blue-500 animate-spin h-3 w-3"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-100"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>

              <svg
                v-else
                class="h-3 w-3"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  d="M21 10C21 10 18.995 7.26822 17.3662 5.63824C15.7373 4.00827 13.4864 3 11 3C6.02944 3 2 7.02944 2 12C2 16.9706 6.02944 21 11 21C15.1031 21 18.5649 18.2543 19.6482 14.5M21 10V4M21 10H15"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <template #content>
              <div class="w-max max-w-lg rounded bg-blue-50 p-2 text-sm">
                Refresh Proxy
              </div>
            </template>
          </Popper>
          <StatusDot
            v-else
            :color="data.activeProxy ? 'positive' : 'error'"
            size="small"
          />
        </div>
      </td>
      <!-- limits modal button -->
      <td v-if="userRole !== 'viewer'" class="whitespace-nowrap px-3 py-4">
        <div class="flex items-center justify-left ml-[1vh]">
          <EditButton
            @click="
              $emit('modal', 'limits', {
                accountId: rows[mainIndex][0].id,
                limits: rows[mainIndex][0].limits,
                platformId: rows[mainIndex][0].platformId,
                platformNames: rows[mainIndex][0].platformNames,
                platformToIdMap: rows[mainIndex][0].platformToIdMap,
                platformIcons: rows[mainIndex][1].url
              })
            "
          />
        </div>
      </td>
      <!-- variable modal button -->
      <td v-if="userRole !== 'viewer'" class="whitespace-nowrap px-3 py-4">
        <div class="flex items-center justify-left ml-[2vh]">
          <EditButton
            @click="
              $emit('modal', 'variable', {
                variables: rows[mainIndex][0].variables,
                accountId: rows[mainIndex][0].id,
                platformId: rows[mainIndex][0].platformId,
                platformNames: rows[mainIndex][0].platformNames,
                platformToIdMap: rows[mainIndex][0].platformToIdMap,
                platformIcons: rows[mainIndex][1].url
              })
            "
          />
        </div>
      </td>

      <td v-if="userRole !== 'viewer'" class="whitespace-nowrap px-3 py-4">
        <div class="flex items-center justify-center space-x-1">
          <button
            :data-sync="true"
            :data-getdataforsync="rows[mainIndex][0].dataForSync"
            name="texau-extension-v2-sync"
            class="flex hidden w-[68px] items-center justify-center rounded-lg border border-blue-600 bg-transparent px-3 py-2 text-xs font-semibold text-blue-600 transition-all duration-200 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 dark:border-blue-400 dark:text-blue-400 dark:hover:bg-blue-400 dark:hover:text-blue-50 dark:focus:ring-blue-400 dark:focus:ring-offset-gray-900"
          >
            <SvgIcon class="-ml-1 mr-2 h-[14px] w-[14px]" name="refresh" />
            Sync
          </button>

          <div class="h-5 w-5">
            <button
              @click="onDeleteClick(mainIndex)"
              class="-m-2 rounded-lg p-2 text-red-600 transition-all duration-200 hover:bg-red-50 focus:outline-none dark:text-red-400 dark:hover:bg-red-500 dark:hover:text-red-50"
            >
              <SvgIcon class="h-5 w-5" name="delete" />
            </button>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
import Avatar from '@/components/Avatar.vue'
import Button from '@/components/Button.vue'
import Entity from '@/components/Entity.vue'
import Select from '@/components/Select.vue'
import GroupSelect from '@/components/GroupSelect.vue'
import StatusDot from '@/components/StatusDot.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import EditButton from '../editButton.vue'
import { findFlagUrlByIso2Code } from 'country-flags-svg'
import { constants } from '@/common/constants'
import imgUrl from '/src/assets/images/default-avatar-dark.svg'
import { amplitudeTrackEvent } from '@/common/functions/eventTracker'
import {
  setAccountProxy,
  deleteProxyByAccountId,
  refreshProxy
} from '@/apis/proxy'
import Popper from 'vue3-popper'
import { mapState } from 'vuex'
import Spinner from '@/components/Spinner.vue'
import { getAccountStatus } from '@/apis/connectedAccounts'
export default {
  name: 'TableBody',
  components: {
    Entity,
    Select,
    GroupSelect,
    Avatar,
    StatusDot,
    Button,
    EditButton,
    SvgIcon,
    Popper,
    Spinner
  },
  emits: ['fetchAllConnectedAccounts'],
  props: {
    rows: {
      type: Array,
      default: [
        [
          {
            name: 'Arman Mazka',
            initials: 'AM',
            caption: 'manager'
          },
          {
            url: 'https://d33wubrfki0l68.cloudfront.net/f60103046fb78a6c83b7daaab83575e7cdf31dff/6e3f8/images/symbols/twitter.svg'
          },
          {
            options: ['proxy1', 'proxy2'],
            selected: 0
          },
          {
            flag: 'https://d33wubrfki0l68.cloudfront.net/46acef9032726697c1149b82c87a9cf911911039/2306f/images/flags/usa.png',
            country: 'USA'
          },
          {
            active: true
          },
          {
            active: true
          }
        ],
        [
          {
            name: 'Arman Mazka',
            initials: 'AM',
            caption: 'manager'
          },
          {
            url: 'https://d33wubrfki0l68.cloudfront.net/f60103046fb78a6c83b7daaab83575e7cdf31dff/6e3f8/images/symbols/twitter.svg'
          },
          {
            options: ['proxy1', 'proxy2'],
            selected: 0
          },
          {
            flag: 'https://d33wubrfki0l68.cloudfront.net/46acef9032726697c1149b82c87a9cf911911039/2306f/images/flags/usa.png',
            country: 'USA'
          },
          {
            active: false
          },
          {
            active: false
          }
        ]
      ]
    },
    proxyData: { type: Array, default: [] },
    desktopData: { type: Array, default: [] }
  },
  data() {
    return {
      componentKey: 0,
      isDeleting: [],
      isDeletingClickedIndex: null,
      tableRows: this.rows,
      testData: [
        {
          Proxy: ['Test 1', 'Test 2'],
          HeaderIcon: 'server'
        },
        {
          Desktop: ['Test 3', 'Test 4'],
          HeaderIcon: 'desktop'
        }
      ],
      showLoadingSpinner: false,
      pollingInterval: null,
      notReadyToUseAccounts: []
    }
  },
  computed: {
    ...mapState('settings', ['userRole']),
    usingDeveloperMode() {
      return JSON.parse(localStorage.getItem('devModeEnable') || false)
    }
  },
  /**
   * Lifecycle hook that is called when the component is mounted.
   * This method filters the rows to find accounts that are not ready to use,
   * and initiates long polling to check their status if any are found.
   *
   * @async
   * @function mounted
   * @returns {void}
   */
  async mounted() {
    this.notReadyToUseAccounts = this.rows
      .filter(row => row[0].readyToUse === false)
      .flatMap(row => row[0].id.split(',').map(id => id.trim()))

    if (this.notReadyToUseAccounts.length > 0) {
      this.longPollingReadyToUseAccounts()
    }
  },
  beforeUnmount() {
    this.stopPolling()
  },
  methods: {
    /**
     * Initiates long polling to check the status of accounts that are not ready to use.
     * This method sets up an interval that checks the account status every 3 seconds.
     * It updates the table rows based on the readiness of accounts and emits an event
     * if any account becomes ready.
     *
     * @async
     * @function longPollingReadyToUseAccounts
     * @returns {void}
     */
    async longPollingReadyToUseAccounts() {
      // Stop any existing polling before starting a new one
      this.stopPolling()
      // Define a function to poll account statuses
      const pollAccounts = async () => {
        // Check if there are no accounts that are not ready to use
        if (!this.notReadyToUseAccounts.length) {
          this.stopPolling() // Stop polling if no accounts are left
          return
        }

        try {
          // Fetch the current status of the accounts
          const { data: accounts } = await getAccountStatus(
            this.notReadyToUseAccounts
          )
          // Create a set of ready account IDs
          const readyAccounts = new Set(
            accounts
              .filter(account => account.readyToUse) // Filter accounts that are ready
              .map(account => account._id) // Map to their IDs
          )

          // If there are no ready accounts, exit the function
          if (!readyAccounts.size) return

          let anyAccountBecameReady = false // Flag to track if any account became ready

          // Update the table rows based on the account readiness
          this.tableRows = this.tableRows.map(row => {
            // Check if the current row's account is not ready
            if (!row[0].readyToUse) {
              // Split the account IDs and check if all are ready
              const accountIds = row[0].id.split(',').map(id => id.trim())
              const allIdsReady = accountIds.every(id => readyAccounts.has(id))

              // If all IDs are ready, update the row's readiness
              if (allIdsReady) {
                anyAccountBecameReady = true // Set the flag to true
                row[0].readyToUse = true // Mark the row as ready
              }
            }
            return row // Return the updated row
          })

          // Emit an event if any account became ready
          if (anyAccountBecameReady) {
            this.$emit('fetchAllConnectedAccounts')
          }

          // Update the list of not ready accounts
          this.notReadyToUseAccounts = Array.from(
            new Set(
              this.tableRows
                .filter(row => !row[0].readyToUse) // Filter rows that are not ready
                .flatMap(row => row[0].id.split(',').map(id => id.trim())) // Flatten the IDs
            )
          )
        } catch (error) {
          // Log any errors that occur during polling
          console.error('Error polling account status:', error)
          this.stopPolling() // Stop polling on error
        }
      }

      // Start the polling process
      await pollAccounts()
      // Set up an interval to continue polling every 3 seconds if there are still accounts not ready
      if (this.notReadyToUseAccounts.length) {
        this.pollingInterval = setInterval(pollAccounts, 3000)
      }
    },

    /**
     * Stops the polling process by clearing the polling interval.
     * This method sets the pollingInterval to null after clearing it,
     * ensuring that no further polling occurs until restarted.
     *
     * @function stopPolling
     * @returns {void}
     */
    stopPolling() {
      if (this.pollingInterval) {
        clearInterval(this.pollingInterval)
        this.pollingInterval = null
      }
    },
    checkDeleting(i) {
      return this.isDeleting[i]
    },
    checkLockStatus(providedByTexau, activeProxyName, desktopNames) {
      let isDesktopNameUsed = desktopNames.find(
        item => item === activeProxyName
      )
      if (isDesktopNameUsed || !providedByTexau) {
        return false
      } else {
        return true
      }
    },

    setDeleteIndex(mainIndex) {
      this.isDeletingClickedIndex = mainIndex
      setTimeout(() => {
        this.isDeletingClickedIndex = null
      }, 6000)
    },

    onDeleteClick(i) {
      this.$emit('showForceDeleteModal', this.rows[i][0].id.split(','))
    },
    async checkChangeProxy(proxyName, accountid, mainIndex, activeProxyName) {
      try {
        // split id for multiple account
        let accountIdArr = accountid.split(',')
        let changeProxyResponse

        //check if proxyName provided is for desktop
        let isdesktopProxy = this.desktopData.find(
          data => data.name === proxyName
        )

        //loop proxy calls for account with multiple Id's
        for (let index = 0; index < accountIdArr.length; index++) {
          //for case of desktop proxy or proxy unassign
          if (isdesktopProxy || proxyName === '') {
            changeProxyResponse = await this.changeProxy(
              proxyName,
              accountIdArr[index],
              mainIndex,
              activeProxyName
            )
          } else {
            changeProxyResponse = await this.changeProxy(
              proxyName,
              accountIdArr[index],
              mainIndex
            )
          }
        }

        if (proxyName === '') {
          this.$emit('proxyEmit', {
            type: 'success',
            msg: `Proxy Unassigned Successfully`
          })
          this.tableRows[mainIndex][7].activeProxy = false
          this.tableRows[mainIndex][7].providedByTexau = false
          this.tableRows[mainIndex][7].activeProxyName = undefined
          this.tableRows[mainIndex][7].activeProxyLocationFlag = null
        } else {
          this.$emit('proxyEmit', {
            type: 'success',
            msg: changeProxyResponse.message
          })

          this.tableRows[mainIndex][7].activeProxyName = proxyName
          this.tableRows[mainIndex][7].activeProxyLocationFlag =
            changeProxyResponse?.data?.location?.countryCode
        }
        this.componentKey += 1
      } catch (error) {
        console.log(error)
        this.$emit('proxyEmit', {
          type: 'fail',
          msg: error
        })
      }
    },

    async changeProxy(proxyName, accountid, mainIndex, activeProxyName) {
      try {
        if (proxyName === '') {
          const isDesktop = this.desktopData.find(
            data => data.name === activeProxyName
          )
          let deleteResponse
          if (isDesktop) {
            deleteResponse = await deleteProxyByAccountId(accountid, 'desktop')
          } else {
            deleteResponse = await deleteProxyByAccountId(accountid)
          }

          if (deleteResponse['success']) {
            return
          } else {
            throw new Error(deleteResponse.message)
          }
        } else {
          let proxy
          proxy = this.proxyData.find(data => data.name === proxyName)

          if (proxy) {
            const response = await setAccountProxy(
              accountid,
              proxy.proxyId,
              'proxyId'
            )
            if (response['success']) {
              return response
            } else {
              throw new Error(response.message)
            }
          } else {
            proxy = this.desktopData.find(data => data.name === proxyName)
            const response = await setAccountProxy(
              accountid,
              proxy._id,
              'desktopId'
            )
            if (response['success']) {
              return response
            } else {
              throw new Error(response.message)
            }
          }
        }
      } catch (error) {
        throw error
      }
    },

    formatDate(d) {
      const newDate = new Date(Date.parse(d))

      const curr_date = newDate.getDate()

      const curr_month = newDate.toLocaleString('en-US', { month: 'short' })

      const curr_yr = newDate.getFullYear()
      return `${curr_date} ${curr_month} ${curr_yr}`
    },
    getFlag(code) {
      if (code) {
        return findFlagUrlByIso2Code(code)
      } else {
        return null
      }
    },
    showLiPremium(data) {
      if (
        data.platformId.includes(constants.LINKEDIN_PLATFORM_ID) &&
        data.isPremium === true
      ) {
        return true
      } else return false
    },
    checkProxyName(name, active, providedByTexau) {
      return (
        !!this.proxyData.find(data => data.name === name) &&
        (active === false || active === undefined) &&
        providedByTexau === true
      )
    },
    async refreshProxy(name) {
      try {
        this.showLoadingSpinner = true
        let proxyData = this.proxyData.find(data => data.name === name)
        let response = await refreshProxy(proxyData.proxyId)
        if (response['success']) {
          this.$router.go()
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('proxyEmit', {
          type: 'fail',
          msg: error
        })
      }
      this.showLoadingSpinner = false
    },
    async copyAccountId(id) {
      amplitudeTrackEvent('Account ID Copied', localStorage.getItem('email'))
      await navigator.clipboard.writeText(id)
      this.$emit('proxyEmit', {
        type: 'success',
        msg: `Account ID copied!`
      })
    }
  }
}
</script>
