<template>
  <div class="min-h-screen bg-gray-50 dark:bg-gray-900">
    <div class="flex h-screen">
      <div class="flex min-w-0 flex-1 flex-col overflow-hidden">
        <main class="flex flex-1 overflow-hidden">
          <div class="flex flex-1 flex-col overflow-y-auto xl:overflow-hidden">
            <div class="flex-1 xl:overflow-y-auto">
              <div
                class="mx-auto max-w-5xl px-4 py-10 sm:px-6 lg:px-8 lg:py-12"
              >
                <!-- Analytics Content -->
                <div class="text-center">
                  <h1
                    class="text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl inline-flex items-center gap-3"
                  >
                    Analytics
                    <span
                      class="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"
                    >
                      Coming Soon
                    </span>
                  </h1>
                  <p class="mt-4 text-lg text-gray-500 dark:text-gray-400">
                    Enable analytics to start collecting data about your
                    LinkedIn performance.
                  </p>
                  <div class="mt-4 text-sm text-gray-600 dark:text-gray-400">
                    **Note:** Currently, analytics are only available for the
                    LinkedIn platform.
                  </div>

                  <!-- Add Preview Button -->
                  <!-- <div class="mt-6">
                    <Button
                      text="Preview Analytics Dashboard"
                      @click="openPreviewModal"
                      leftIcon="eye"
                    />
                  </div> -->

                  <!-- New Section: Connected Accounts for Specific Platform -->
                  <div class="my-12">
                    <h2 class="text-2xl font-semibold mb-4">
                      Connected Accounts
                    </h2>
                    <div v-if="isLoadingAccounts" class="flex justify-center">
                      <Spinner size="large" />
                    </div>
                    <div v-else>
                      <div class="flex flex-col">
                        <div
                          class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"
                        >
                          <div
                            class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
                          >
                            <div
                              class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 dark:ring-gray-700 md:rounded-lg"
                            >
                              <table
                                class="min-w-full divide-y divide-gray-200 dark:divide-gray-700"
                              >
                                <thead class="bg-gray-50 dark:bg-gray-700">
                                  <tr>
                                    <th
                                      scope="col"
                                      class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 dark:text-gray-50"
                                    >
                                      Account Name
                                    </th>
                                    <th
                                      scope="col"
                                      class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 dark:text-gray-50"
                                    >
                                      Platform
                                    </th>
                                    <th
                                      scope="col"
                                      class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 dark:text-gray-50"
                                    >
                                      Status
                                    </th>
                                    <th
                                      scope="col"
                                      class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 dark:text-gray-50"
                                    >
                                      Actions
                                    </th>
                                  </tr>
                                </thead>
                                <tbody
                                  class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
                                >
                                  <tr
                                    v-for="account in accountsForPlatform"
                                    :key="account._id"
                                    class="hover:bg-gray-50 dark:hover:bg-gray-800"
                                  >
                                    <td
                                      class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
                                    >
                                      {{ account.name }}
                                    </td>
                                    <td
                                      class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
                                    >
                                      {{ account.platform.label }}
                                    </td>
                                    <td
                                      class="whitespace-nowrap px-3 py-4 text-sm"
                                    >
                                      <span
                                        :class="
                                          statusClass(
                                            account.credentials.isExpired
                                          )
                                        "
                                      >
                                        {{
                                          account.credentials.isExpired
                                            ? 'Expired'
                                            : 'Active'
                                        }}
                                      </span>
                                    </td>
                                    <td
                                      class="whitespace-nowrap px-3 py-4 text-sm"
                                    >
                                      <Button
                                        v-if="!account.analyticsEnabled"
                                        text="Enable Analytics"
                                        @click="enableAnalytics(account)"
                                        size="small"
                                        :showLoader="
                                          loadingAccountIds.includes(
                                            account._id
                                          )
                                        "
                                        :disabled="
                                          loadingAccountIds.includes(
                                            account._id
                                          )
                                        "
                                      />
                                      <span
                                        v-else
                                        class="text-green-500 font-semibold"
                                        >Enabled</span
                                      >
                                    </td>
                                  </tr>
                                  <tr v-if="accountsForPlatform.length === 0">
                                    <td
                                      colspan="4"
                                      class="px-3 py-4 text-sm text-center text-gray-500"
                                    >
                                      No accounts found for this platform.
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- End of New Section -->
                  <div
                    class="mt-4 p-3 bg-yellow-50 border-l-4 border-yellow-400 text-yellow-800 rounded-md shadow-sm"
                  >
                    <strong class="block text-sm font-semibold">
                      TexAu Analytics will be chargeable at $19 per month
                      starting 1st March, 2025. You will not be charged during
                      the beta period, and we will ensure to communicate before
                      the public release.
                    </strong>
                  </div>
                  <!-- What data we collect -->
                  <div
                    class="mt-8 mb-24 rounded-lg bg-gradient-to-br from-blue-50 to-blue-100 p-8 shadow-lg dark:from-blue-900/50 dark:to-blue-800/50"
                  >
                    <h3
                      class="mb-6 text-xl font-semibold text-center text-blue-900 dark:text-blue-100"
                    >
                      What data we collect
                    </h3>
                    <div class="mt-4 text-sm text-blue-700 dark:text-blue-300">
                      <div class="grid grid-cols-1 gap-8 md:grid-cols-2">
                        <!-- Profile Analytics Section -->
                        <div class="space-y-4 text-center">
                          <h4
                            class="font-medium text-lg text-blue-800 dark:text-blue-200"
                          >
                            Profile Analytics
                          </h4>
                          <div class="flex items-center justify-center">
                            <ul class="space-y-3 text-left mx-auto max-w-sm">
                              <!-- <li class="flex items-center space-x-3">
                                <svg
                                  class="h-5 w-5 text-blue-500"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                                <span>Profile Growth</span>
                              </li> -->
                              <li class="flex items-center space-x-3">
                                <svg
                                  class="h-5 w-5 text-blue-500"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                                <span>Likes</span>
                              </li>
                              <li class="flex items-center space-x-3">
                                <svg
                                  class="h-5 w-5 text-blue-500"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                                <span>Followers</span>
                              </li>
                              <li class="flex items-center space-x-3">
                                <svg
                                  class="h-5 w-5 text-blue-500"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                                <span>Connections</span>
                              </li>
                              <li class="flex items-center space-x-3">
                                <svg
                                  class="h-5 w-5 text-blue-500"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                                <span>Profile View Count</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <!-- Post Analytics Section -->
                        <div class="space-y-4 text-center">
                          <h4
                            class="font-medium text-lg text-blue-800 dark:text-blue-200"
                          >
                            Post Analytics
                          </h4>
                          <div class="flex items-center justify-center">
                            <ul class="space-y-3 text-left mx-auto max-w-sm">
                              <!-- <li class="flex items-center space-x-3">
                                <svg
                                  class="h-5 w-5 text-blue-500"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                                <span>Post Growth</span>
                              </li> -->
                              <!-- <li class="flex items-center space-x-3">
                                <svg
                                  class="h-5 w-5 text-blue-500"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                                <span>Engagement</span>
                              </li> -->
                              <li class="flex items-center space-x-3">
                                <svg
                                  class="h-5 w-5 text-blue-500"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                                <span>Impressions</span>
                              </li>
                              <li class="flex items-center space-x-3">
                                <svg
                                  class="h-5 w-5 text-blue-500"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                                <span>Comments</span>
                              </li>
                              <li class="flex items-center space-x-3">
                                <svg
                                  class="h-5 w-5 text-blue-500"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                                <span>Share Count</span>
                              </li>

                              <li class="flex items-center space-x-3">
                                <svg
                                  class="h-5 w-5 text-blue-500"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                                <span>Post Content: Text, Media , URL</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>

    <!-- Preview Modal -->
    <TransitionRoot appear :show="isPreviewModalOpen" as="template">
      <Dialog as="div" @close="closePreviewModal" class="relative z-50">
        <TransitionChild
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25 dark:bg-black/40" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-4 text-center"
          >
            <TransitionChild
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel
                class="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 text-left align-middle shadow-xl transition-all"
              >
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Analytics Dashboard Preview
                </DialogTitle>
                <div class="mt-4">
                  <img
                    :src="previewImageUrl"
                    alt="Analytics Dashboard Preview"
                    class="w-full rounded-lg"
                  />
                </div>
                <div class="mt-4">
                  <Button text="Close" @click="closePreviewModal" />
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Terms and Analytics Data Collection Modal -->
    <TransitionRoot appear :show="showTermsModal" as="template">
      <Dialog as="div" @close="closeTermsModal" class="relative z-50">
        <TransitionChild
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25 dark:bg-black/40" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-4 text-center"
          >
            <TransitionChild
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel
                class="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 text-left align-middle shadow-xl transition-all"
              >
                <DialogTitle
                  as="h3"
                  class="text-2xl font-bold leading-6 text-gray-900 dark:text-white mb-4"
                >
                  Analytics Data Collection Terms
                </DialogTitle>

                <div class="mt-4 space-y-4">
                  <div
                    class="mt-4 p-3 bg-yellow-50 border-l-4 border-yellow-400 text-yellow-800 rounded-md shadow-sm"
                  >
                    <strong class="block text-sm font-semibold">
                      TexAu Analytics will be chargeable at $19 per month
                      starting 1st March, 2025. You will not be charged during
                      the beta period, and we will ensure to communicate before
                      the public release.
                    </strong>
                  </div>

                  <div>
                    <h4
                      class="text-lg font-semibold text-gray-900 dark:text-white mb-2"
                    >
                      What Data We Collect
                    </h4>
                    <p class="text-gray-600 dark:text-gray-400">
                      We collect data to help you understand your LinkedIn
                      performance, including:
                    </p>
                    <div class="mt-2 flex items-center gap-[100px]">
                      <ul
                        class="list-disc list-inside text-gray-600 dark:text-gray-400 space-y-1"
                      >
                        <li>Likes</li>
                        <li>Followers</li>
                        <li>Connections</li>
                        <li>Profile View Count</li>
                      </ul>

                      <ul
                        class="list-disc list-inside text-gray-600 dark:text-gray-400 space-y-1"
                      >
                        <li>Impressions</li>
                        <li>Comments</li>
                        <li>Share Count</li>
                        <li>Post Content: Text, Media , URL</li>
                      </ul>
                    </div>
                  </div>

                  <div>
                    <h4
                      class="text-lg font-semibold text-gray-900 dark:text-white mb-2"
                    >
                      How We Collect Data
                    </h4>
                    <p class="text-gray-600 dark:text-gray-400">
                      Data is collected using our automations, which run on a
                      daily schedule. These automations gather insights from
                      accounts you explicitly enable for analytics tracking and
                      do not consume your cloud runtime.
                    </p>
                  </div>

                  <div>
                    <h4
                      class="text-lg font-semibold text-gray-900 dark:text-white mb-2"
                    >
                      How We Use Your Data
                    </h4>
                    <p class="text-gray-600 dark:text-gray-400">
                      Your analytics data is used to:
                    </p>
                    <ul
                      class="mt-2 list-disc list-inside text-gray-600 dark:text-gray-400 space-y-1"
                    >
                      <li>Build your analytics page to track performance.</li>
                      <li>Show growth and engagement trends.</li>
                      <li>
                        Provide actionable recommendations using our advanced AI
                        models and Large Language Models (LLMs).
                      </li>
                    </ul>
                  </div>

                  <div>
                    <h4
                      class="text-lg font-semibold text-gray-900 dark:text-white mb-2"
                    >
                      Data Protection
                    </h4>
                    <p class="text-gray-600 dark:text-gray-400">
                      We implement industry-standard security measures to
                      safeguard your data. You have full control to delete your
                      analytics data at any time through your account, and the
                      deletion process will be completed within 14 days.
                    </p>
                  </div>
                </div>

                <div class="mt-8 flex justify-end">
                  <Button
                    text="Accept & Continue"
                    @click="acceptTermsAndContinue"
                    leftIcon="TickIcon"
                  />
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import Button from '@/components/Button.vue'
import Spinner from '@/components/Spinner.vue'
import { getConnectedAccounts } from '@/apis/automation-store/Page1' // Adjust the import path as necessary
import {
  fetchEnabledAnalytics,
  enableAnalyticsForAccount
} from '@/apis/analytics'
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionRoot,
  TransitionChild
} from '@headlessui/vue'
import { updateUserDetail } from '@/apis/user'

const emit = defineEmits(['success', 'error'])
const store = useStore()
// Reactive state for loading and accounts data
const isLoadingAccounts = ref(false)
const accountsForPlatform = ref([])
const loadingAccountIds = ref([])

// The specific platform ID to fetch accounts for (LinkedIn)
const platformId = '622f03eb770f6bba0b8facaa'

// Modal state and functions
const isPreviewModalOpen = ref(false)
const previewImageUrl = ref(
  'https://ci3.googleusercontent.com/meips/ADKq_NYrKrWX12Pqiil3NIZud2v0tfs7_lqgYTiilYd4fclrN6dpVXX9600xHjPdWxMT6NIg2jO98ZL3j925gt2zTIUb2r4fiDZ2I2Qli7kgW5Tu6WesxeJ5SD5ylh6-u-f4R__zPv5a0GtU4GSYI37twtzvgxfR-VuQ8MeyzlHoclKX16CKLpcMOwgfuw71ml9tvXngZr1mgo2f6jU-GMIJGLuKoDgm1eN11SSzDKbqoGRe14vrnxkz4JYk78vGldHF_yKkB8svbNcnZMFLLT3hVPGkCn4-x4bcneuI70p-xeZ8irrw3I_Fgbc-zaT3aNc0TXucTtZNlOylj3F3j500j3byqQx4FUKTDYrjvzQIHdwkkMVkoPKUJmv4U4ZKYQ7zmDsiq_WGGA=s0-d-e1-ft#https://texau-008a23623961.intercom-mail.com/i/o/iabchv6h/1302877713/95d0a917bec366443a22d9defb3b/Analytics.jpg?expires=1751410836&signature=db647cb5a988f5fae1802c00dab51d9a1a28f89458c4739f07e319e8c370e453&req=dSMnFMF5moZeWvMW2XS%2FiR0fItlS7ePrwc3%2Fi7HQLrk2yvdBamM%3D%0A'
)

const openPreviewModal = () => {
  isPreviewModalOpen.value = true
}

const closePreviewModal = () => {
  isPreviewModalOpen.value = false
}

// Function to fetch accounts for the specified platform ID
const fetchAccountsForPlatform = async () => {
  isLoadingAccounts.value = true
  try {
    // Fetch connected accounts and enabled analytics concurrently
    const [connectedResponse, enabledAnalyticsResponse] = await Promise.all([
      getConnectedAccounts(platformId),
      fetchEnabledAnalytics()
    ])

    if (connectedResponse.success) {
      // Extract IDs of accounts with analytics enabled
      const enabledAccountIds = enabledAnalyticsResponse.data.map(
        account => account._id
      )

      // Map through connected accounts and set analyticsEnabled based on enabledAccountIds
      accountsForPlatform.value = connectedResponse.data.map(account => ({
        ...account,
        analyticsEnabled: enabledAccountIds.includes(account._id)
      }))
    } else {
      emit(
        'error',
        `Error fetching connected accounts: ${connectedResponse.message}`
      )
      accountsForPlatform.value = []
    }
  } catch (error) {
    emit('error', `Error fetching accounts: ${error.message}`)
    accountsForPlatform.value = []
  } finally {
    isLoadingAccounts.value = false
  }
}

// Function to enable analytics for a specific account
const enableAnalytics = async account => {
  loadingAccountIds.value.push(account._id)
  try {
    const response = await enableAnalyticsForAccount(account._id)
    if (response.success) {
      account.analyticsEnabled = true
      emit('success', `Analytics enabled for account: ${account.name}`)
    } else {
      emit('error', `Error enabling analytics: ${response.message}`)
    }
  } catch (error) {
    console.error('Error enabling analytics:', error)
    emit('error', `Error enabling analytics: ${error.message}`)
  } finally {
    loadingAccountIds.value = loadingAccountIds.value.filter(
      id => id !== account._id
    )
  }
}

// Helper function to determine the CSS class based on account status
const statusClass = isExpired => {
  return isExpired ? 'text-red-500' : 'text-green-500'
}

// Fetch accounts when the component is mounted
onMounted(async () => {
  await fetchAccountsForPlatform()
})

const showTermsModal = ref(false)

const closeTermsModal = () => {
  // Prevent closing by clicking outside
  return
}

const acceptTermsAndContinue = async () => {
  showTermsModal.value = false
  await updateUserDetail({
    analyticsTermAgreementAccepted: true
  })
  store.dispatch('user/updateUserDataAnalytics', {
    analyticsTermAgreementAccepted: true
  })
}

const termsAccepted = computed(
  () => store.state.user.userData?.analyticsTermAgreementAccepted || false
)

onMounted(() => {
  // Check if user has previously accepted terms
  if (termsAccepted.value) {
    showTermsModal.value = false
  } else {
    showTermsModal.value = true
  }
})
</script>

<style scoped>
/* Add any necessary styles here */
</style>
