<template>
  <div class="relative h-full flex-1">
    <div class="h-full overflow-y-auto overflow-x-hidden mt-2">
      <div class="flex flex-col">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
          >
            <div class="overflow-hidden">
              <table class="min-w-full">
                <thead class="bg-gray-50 dark:bg-gray-700">
                  <tr>
                    <th
                      scope="col"
                      class="heading-primary min-w-[12rem] rounded-l-lg"
                    >
                      Name
                    </th>

                    <th scope="col" class="heading-primary">Email</th>
                    <th scope="col" class="heading-primary">Workspace Name</th>
                    <th scope="col" class="heading-primary">Role</th>

                    <th scope="col" class="heading-primary rounded-r-lg">
                      <span class="sr-only"> Actions </span>
                    </th>
                  </tr>
                </thead>
                <!-- Skeleton loader -->
                <tbody
                  v-if="initialLoading"
                  class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
                >
                  <tr v-for="ele in 4" :key="ele">
                    <td v-for="ele in 4" :key="ele" class="data-primary">
                      <Skeleton
                        height="41px"
                        width="313px"
                        borderRadius="8px"
                      ></Skeleton>
                    </td>
                  </tr>
                </tbody>

                <tbody
                  v-else
                  class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
                  :key="componentKey"
                >
                  <tr v-for="(item, index) in userData" :key="index" ref="user">
                    <td class="data-primary">
                      <div>
                        <div>
                          <Input
                            text="Enter name"
                            type="text"
                            :readonly="true"
                            :modelValue="item.name || 'TexAu User'"
                            label="Label"
                            labelClass="sr-only"
                            :key="index"
                            @change="onNameInputChange($event, index)"
                            @keyup="onNameInputChange($event, index)"
                          />
                        </div>
                      </div>
                    </td>

                    <td class="data-primary">
                      <div>
                        <div>
                          <input
                            :readonly="true"
                            type="text"
                            placeholder="Enter email"
                            v-model="item.email"
                            class="block w-full rounded-lg border px-3 py-2.5 placeholder-gray-500 transition-all duration-200 dark:placeholder-gray-400 border-gray-300 caret-blue-600 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                            :class="[
                              {
                                'blur-on-lose-focus': !item.isAdd
                              }
                            ]"
                          />
                        </div>
                      </div>
                    </td>
                    <td class="data-primary">
                      <div>
                        <div>
                          <input
                            :readonly="true"
                            type="text"
                            placeholder="Enter email"
                            v-model="item.workspaceName"
                            class="block w-full rounded-lg border px-3 py-2.5 placeholder-gray-500 transition-all duration-200 dark:placeholder-gray-400 border-gray-300 caret-blue-600 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                          />
                        </div>
                      </div>
                    </td>

                    <td class="data-primary">
                      <div>
                        <div>
                          <Input
                            type="text"
                            :readonly="true"
                            text="Role"
                            :modelValue="
                              item.role.charAt(0).toUpperCase() +
                              item.role.slice(1)
                            "
                            label="Label"
                            labelClass="sr-only"
                            :key="index"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
import Select from '@/components/Select.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { mapGetters } from 'vuex'
import { getAllOrganisationUsers } from '@/apis/workspace'

export default {
  name: 'userRolesSection',
  components: {
    SvgIcon,
    Input,
    Button,
    Select
  },

  data() {
    return {
      initialLoading: false
    }
  },
  props: {
    userData: {
      type: Array,
      default: []
    }
  },
  computed: {
    ...mapGetters('settings', ['getMetaData']),

    getRoles() {
      const allRoles = this.getMetaData['roles']
        ? this.getMetaData['roles'].map(function (role) {
            return {
              value: role.name,
              label: role.name.charAt(0).toUpperCase() + role.name.slice(1)
            }
          })
        : []
      return allRoles.filter(role => role.value !== 'owner')
    }
  },
  methods: {
    getRoleName(id) {
      const role = this.getMetaData['roles'].find(role => role.id === id)

      return role.name
    }
  },
  watch: {},
  async beforeMount() {
    this.initialLoading = true
    this.userData.length = 0
    const workspaceMemberResponse = await getAllOrganisationUsers(
      window.$cookies.get('organisationId')
    )
    for (const workspaceMemberResponseElement of workspaceMemberResponse.data) {
      const firstName = workspaceMemberResponseElement.firstname
        ? workspaceMemberResponseElement.firstname
        : ''
      const lastName = workspaceMemberResponseElement.lastname
        ? workspaceMemberResponseElement.lastname
        : ''
      for (const ele of workspaceMemberResponseElement.workspaces) {
        this.userData.push({
          name: firstName || lastName ? firstName + ' ' + lastName : '',
          email: workspaceMemberResponseElement.email,
          workspaceName: ele.workspaceName,
          role: this.getRoleName(ele.roleId)
        })
      }
    }

    this.initialLoading = false
  }
}
</script>

<style scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .heading-primary {
    @apply px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50;
  }

  .data-primary {
    @apply whitespace-nowrap px-3 py-4;
  }
}
.blur-on-lose-focus:not(:focus) {
  color: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
</style>
