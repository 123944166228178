import axios from '@/common/axios'

const getAllPlatforms = async (start = 0, limit = 50, type = 'automation') => {
  try {
    const response = await axios.get(
      `/platforms?start=${start}&limit=${limit}&type=${type}`
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const getPlatformOperations = async (
  platformId,
  start = 0,
  limit = 20,
  searchText,
  category,
  signal
) => {
  try {
    const response = await axios.get(`/platforms/${platformId}/operations`, {
      params: {
        start,
        limit,
        q: searchText,
        category
      },
      signal
    })
    return response.data
  } catch (error) {
    if (error.toString().includes('canceled')) {
      return 'canceled'
    }
    return error.response.data
  }
}

const getOperationVariableServices = async (platformId, operationId) => {
  try {
    const response = await axios.get(
      `/platforms/${platformId}/operations/${operationId}/variables`
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const getPlatformVariables = async platformOperationId => {
  try {
    const response = await axios.get(
      `/platforms/operations/${platformOperationId}`
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const getPlatformInfo = async platformId => {
  try {
    const response = await axios.get(`/platforms/${platformId}`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const getConnectedAccounts = async (platformId, onlyReady) => {
  try {
    const response = await axios.get(`/accounts`, {
      params: {
        platformId,
        onlyReady
      }
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export {
  getAllPlatforms,
  getConnectedAccounts,
  getOperationVariableServices,
  getPlatformInfo,
  getPlatformOperations,
  getPlatformVariables
}
