import axios from '@/common/axios'

const getPlatformDetails = async platformId => {
  try {
    const response = await axios.get(`/platforms/${platformId}`)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const getAllConnectedAccounts = async (start = 0, limit = 20, onlySocial) => {
  try {
    const response = await axios.get(`/accounts`, {
      params: { start, limit, onlySocial }
    })
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const getConnectedAccountsUi = async (start = 0, limit = 20) => {
  try {
    const response = await axios.get(`/accounts/ui`, {
      params: { start, limit }
    })
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const deleteConnectedAccount = async accountId => {
  try {
    const response = await axios.delete(`/accounts/${accountId}`)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const deleteConnectedAccountForce = async accountId => {
  try {
    const response = await axios.delete(`/accounts/${accountId}/force`)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const updateVariable = async (accountId, data) => {
  try {
    const response = await axios.put(`/accounts/${accountId}/variables`, data)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const deleteVariable = async (accountId, data) => {
  try {
    const response = await axios.delete(`/accounts/${accountId}/variables`, {
      data: data
    })

    return response.data
  } catch (e) {
    return e.response.data
  }
}

const validateCredentials = async (credentials, platformId) => {
  try {
    const response = await axios.post(`/accounts/validate`, {
      credentials,
      platformId
    })
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const getAccountVariables = async accountId => {
  try {
    const response = await axios.get(`/accounts/${accountId}/variables`)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

/**
 * Retrieves the status of multiple accounts based on their IDs.
 * @param {Array<string>} ids - An array of account IDs for which to retrieve the status.
 * @returns {Promise<Object>} - The response data containing the status of the accounts or an error object.
 */
const getAccountStatus = async ids => {
  try {
    const response = await axios.post(`/accounts/status`, {
      ids
    })
    return response.data
  } catch (e) {
    return handleError(e, 'An error occurred while retrieving account status.')
  }
}

/**
 * Handles API errors, providing fallback error data.
 * @param {Object} error - The error object caught from the Axios request.
 * @param {String} fallbackMessage - A fallback message to return if the error response is unavailable.
 * @returns {Object|String} - The error response data or a fallback message.
 */
const handleError = (error, fallbackMessage) => {
  return error?.response?.data || { message: fallbackMessage }
}

export {
  getAllConnectedAccounts,
  getConnectedAccountsUi,
  getPlatformDetails,
  deleteConnectedAccount,
  deleteConnectedAccountForce,
  updateVariable,
  deleteVariable,
  validateCredentials,
  getAccountVariables,
  getAccountStatus
}
