export const SAVE_USER_DATA = (state, payload) => {
  state.userData = payload
}

export const UPDATE_USER_DATA = (state, payload) => {
  const key = Object.keys(payload)[0]
  state.userData[key] = payload[key]
}

export const UPDATE_USER_DATA_WORKFLOW = (state, payload) => {
  const key = Object.keys(payload)[0]
  state.userData[key] = payload[key]
}

export const UPDATE_USER_DATA_ANALYTICS = (state, payload) => {
  const key = Object.keys(payload)[0]
  state.userData[key] = payload[key]
}

export const SAVE_EXECUTION_TIME_LEFT = (state, payload) => {
  state.executionTimeLeft = payload
}

export const SAVE_NOTIFICATION_NUMBER = (state, payload) => {
  state.notificationNumber = payload
}

export const SAVE_WORKSPACE_DATA = (state, payload) => {
  state.userWorkspaceData = payload
}

export const SAVE_EMAIL_VERIFIERS_LEFT = (state, payload) => {
  state.emailVerifiersLeft = payload
}

export const SAVE_EMAIL_ENRICHMENTS_LEFT = (state, payload) => {
  state.emailEnrichmentsLeft = payload
}

export const SAVE_SOCIAL_ACCOUNTS_LEFT = (state, payload) => {
  state.socialAccountsLeft = payload
}

export const SAVE_SOCIAL_ACCOUNTS_ALLOWED = (state, payload) => {
  state.socialAccountsAllowed = payload
}

export const SAVE_PROXY_ACCESS = (state, payload) => {
  state.proxyAccess = payload
}

export const CHANGE_EXPANDED_DEFAULT = (state, payload) => {
  state.isExpandedDefault = payload
}

export const SAVE_DESKTOP_ACCESS = (state, payload) => {
  state.desktopUnlimited = payload
}
export const SAVE_AI_ACCESS = (state, payload) => {
  state.aiAccess = payload
}
export const SAVE_INTEGRATION_ACCESS = (state, payload) => {
  state.integrationsAccess = payload
}
export const SAVE_API_ACCESS = (state, payload) => {
  state.apiAccess = payload
}

export const SAVE_ADDITIONAL_DATA = (state, payload) => {
  state.additionalData = payload
}

export const SAVE_DEV_MODE_STATUS = (state, payload) => {
  console.log(payload)
  state.devModeStatus = payload
}
