<template>
  <div
    class="relative flex flex-1 border-l border-gray-200 dark:border-gray-700"
  >
    <div class="relative flex flex-1 flex-col justify-between py-6">
      <div class="p-4" v-if="$route.query.source === 'sheet'">
        <div class="rounded-md bg-yellow-50 p-4">
          <div class="flex">
            <div class="flex-shrink-0">
              <svg
                class="h-5 w-5 text-yellow-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="ml-3">
              <p class="text-sm text-yellow-700">
                Watch Row feature will be activated after enabling Watch Row
                toggle and saving a schedule
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="loading" class="flex h-full items-center justify-center">
        <Spinner size="large" />
      </div>
      <div v-else class="grid grid-cols-2">
        <div>
          <div v-if="$route.query.source === 'sheet'" class="px-8">
            <Switch
              v-model="watchRowToggle"
              label="Watch Row"
              caption="if this enable then wherever we found a new row in your sheet we will run the workflow as per your time interval"
            />
          </div>

          <Schedulingmodal
            from="store"
            class="mb-2"
            :watchRowToggle="watchRowToggle"
            @success="success"
            @error="error"
            @changeToggleWatchRowValue="e => (watchRowToggle = e)"
          />
        </div>
      </div>

      <div class="mt-auto flex items-center justify-between px-6">
        <Button
          color="tertiary"
          text="Previous"
          leftIcon="left-arrow"
          @click="prevButtonClick()"
        />
        <div>
          <p class="font-bold">
            Save your scheduling settings before clicking
            <span class="text-blue-600">Run</span>
          </p>
        </div>

        <Button
          v-if="isScheduled"
          :showLoader="buttonLoading"
          @click="stopSchedule()"
          text="Stop Schedule"
          color="danger"
          rightIcon="right-arrow"
        />
        <div v-else>
          <Button
            v-if="$route.query.source === 'input'"
            :showLoader="buttonLoading"
            :color="validationStatus ? 'successFilled' : 'gray'"
            :disabled="validationStatus ? false : true"
            @click="nextButtonClick()"
            :text="automationScheduleData ? 'Schedule' : 'Run'"
            rightIcon="right-arrow"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '../Button.vue'
import Select from '../Select.vue'
import Scheduling from './Scheduling.vue'
import Schedulingmodal from '@/components/automationStore/SchedulingModal.vue'
import { pauseSchedule } from '@/apis/workflows'
import { WORKFLOW_CREATED_FROM } from '@/common/constants'
import { mapActions, mapState } from 'vuex'
import Spinner from '@/components/Spinner.vue'
import Switch from '../Switch.vue'

export default {
  name: 'SchedulingMode',
  components: { Select, Button, Scheduling, Schedulingmodal, Spinner, Switch },
  data() {
    return {
      workflowId: null,
      nodeId: null,
      loading: false,
      buttonLoading: false,
      watchRowToggle: false
    }
  },
  mounted() {
    this.watchRowToggle =
      this.automationScheduleData?.isWatchRowEnabled || false
  },
  computed: {
    ...mapState('automationStore', ['automationStoreData']),
    ...mapState('automationStore', ['automationScheduleData']),
    ...mapState('automationStore', ['automationInputs']),
    ...mapState('automationStore', ['workflowIdCreated']),
    ...mapState('automationStore', ['executionId']),
    ...mapState('automationStore', ['AutomationStatus']),
    ...mapState('automationStore', ['connectedAccountId']),
    ...mapState('automationStore', ['automationNameText']),
    ...mapState('automationStore', ['delayAutomationNaming']),
    ...mapState('automationStore', ['validationStatus']),
    ...mapState('user', ['desktopUnlimited', 'userData'])
  },
  props: {
    isScheduled: {
      required: true
    }
  },
  methods: {
    ...mapActions('automationStore', ['addAutomationData']),
    ...mapActions('automationStore', ['toggleWorkflowIdCreated']),
    ...mapActions('automationStore', ['addExecutionId']),
    ...mapActions('automationStore', ['setDelayAutomationNaming']),

    async stopSchedule() {
      try {
        const response = await pauseSchedule(this.$route.query.workflowId)
        if (response['success']) {
          this.$emit('success', 'Automation Schedule Stopped Successfully')
          this.$emit('setScheduledStatus', false)
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('error', error)
      }
    },
    error(e) {
      console.log('error', e)
      this.$emit(
        'error',
        e ||
          'Failed to add or update the schedule. Please try again in some time.'
      )
    },
    success(e) {
      this.$emit('success', e)
    },
    nextButtonClick() {
      if (this.validationStatus === true) {
        this.buttonLoading = true
        this.$emit('AfterFormValidate')
      } else {
        this.$emit('nextButtonClick', {
          comp: 'run-comp',
          highlight: 4,
          optionalSidebarType: 'run',
          optionalSidebarItem: 'start',
          optionalSidebarSearch: true,
          startAutomation: true,
          runButtonOnHeader: true
        })
      }
    },
    prevButtonClick() {
      this.$emit('nextButtonClick', {
        comp: `${this.$route.query.source}-comp`,
        highlight: 1,
        optionalSidebarType: 'default',
        optionalSidebarItem: 'center',
        optionalSidebarSearch: false
      })
    }
  }
}
</script>

<style></style>
